import React from "react";
import Pattern from "../components/Pattern";

const Collection=()=>{
    return(
        <>
        <Pattern 
        isCollection={true}
        />
        </>
    )
}
export default Collection;