exports.tag=[
    {
    "tag": "white"
    },
    {
    "tag": "red"
    },
    {
    "tag": "mild"
    },
    {
    "tag": "blue"
    },
    {
    "tag": "orange"
    },
    {
    "tag": "pink"
    },
    {
    "tag": "black"
    },
    {
    "tag": "yellow"
    },
    {
    "tag": "grey"
    },
    {
    "tag": "brown"
    },
    {
    "tag": "purple"
    },
    {
    "tag": "beige"
    },
    {
    "tag": "navy"
    },
    {
    "tag": "peach"
    },
    {
    "tag": "teal"
    },
    {
    "tag": "maroon"
    },
    {
    "tag": "gold"
    },
    {
    "tag": "dark"
    },
    {
    "tag": "pastel"
    },
    {
    "tag": "neon"
    },
    {
    "tag": "skin"
    },
    {
    "tag": "rainbow"
    },
    {
    "tag": "retro"
    },
    {
    "tag": "vintage"
    },
    {
    "tag": "food"
    },
    {
    "tag": "summer"
    },
    {
    "tag": "light"
    },
    {
    "tag": "cream"
    },
    {
    "tag": "earth"
    },
    {
    "tag": "coffie"
    },
    {
    "tag": "nature"
    },
    {
    "tag": "warm"
    },
    {
    "tag": "spring"
    },
    {
    "tag": "night"
    },
    {
    "tag": "space"
    },
    {
    "tag": "winter"
    },
    {
    "tag": "sunset"
    },
    {
    "tag": "chirstmas"
    },
    {
    "tag": "kids"
    },
    {
    "tag": "happy"
    },
    {
    "tag": "cold"
    },
    {
    "tag": "fail"
    },
    {
    "tag": "wedding"
    },
    {
    "tag": "halloween"
    }
]