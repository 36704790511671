import React from "react";
import Pattern from "../components/Pattern";

const Random=()=>{
    return(
        <>
        <Pattern
        isColorSet={true}
        url={"https://colorhunt2.onrender.com/color/getallrecentcolors"}/>
        </>
    )
}

export default Random;