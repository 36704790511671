import React from "react";

const Error=()=>{
    return(
        <>
        <h3>404 Not Found</h3>
        </>
    )
}

export default Error;